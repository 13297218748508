import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

import { getSessionStorageItem } from "@utils/SessionStorage";
import {
  SECURE_SIMPLEPRACTICE_SIGNIN,
  SECURE_URL_SESSION_STORAGE,
} from "@constants/index";

import styles from "./LinkRedirectSignin.module.scss";

export type LinkRedirectSigninType = {
  linkCopy: string;
};

const LinkRedirectSignin: React.FC<LinkRedirectSigninType> = ({ linkCopy }) => {
  const [secureUrl, setSecureUrl] = useState<string | null>(null);
  const href = secureUrl || SECURE_SIMPLEPRACTICE_SIGNIN;

  useEffect(() => {
    if (typeof window === "undefined") return;

    const ssUrl = getSessionStorageItem(SECURE_URL_SESSION_STORAGE);

    if (Boolean(ssUrl)) {
      setSecureUrl(ssUrl);
    }
  }, []);

  return (
    <Link to={href} className={styles.redirectLink}>
      {linkCopy}
    </Link>
  );
};

export default LinkRedirectSignin;

import { Link } from "gatsby";
import React from "react";
import { notFoundFeatured } from "./notFoundFeaturedData";

type NotFoundFeaturedListProps = {
  featuredList: notFoundFeatured[];
};

const NotFoundFeaturedList: React.FC<NotFoundFeaturedListProps> = ({
  featuredList,
}) => (
  <>
    {featuredList.map(({ urlLink, urlText, copy }) => (
      <div key={`${Date.now()}-${urlText}`}>
        <Link to={urlLink}>{urlText}</Link>
        <p>{copy}</p>
      </div>
    ))}
  </>
);

export default NotFoundFeaturedList;

import React from "react";
import styles from "./AlternativeHeader.module.scss";
import Logo from "../../images/simplePracticeBlueLogo.png";
import { Link } from "gatsby";

const AlternativeHeaderSignUpForm: React.FC = () => {
  return (
    <div
      className={`${styles.containerAlternativeHeader} ${styles.containerAlternativeHeaderBuilder}`}
    >
      <a href="/">
        <svg className={styles.logo}>
          <use href="/icons/sp-logo.svg#sp-logo-forest"></use>
        </svg>
        {/* <img src={Logo} className={styles.logo} alt="Logo Simple Practice" /> */}
      </a>
      <div className={styles.containerPractitioner}>
        <img
          className={styles.healthBagIcon}
          src="/icons/user.svg"
          alt="Health Bag Icon3"
        />
        <Link to="/c/client-interim/" className={styles.linkTo}>
          Are you a client?
        </Link>
      </div>
    </div>
  );
};

export default AlternativeHeaderSignUpForm;

import React from "react";
import containerStyles from "./Container.module.scss";

import "../../../layouts/main.scss";

type VariantType =
  | "w--80"
  | "shorter"
  | "narrow"
  | "smaller"
  | "full-width--pricing";

type ConatinerProps = {
  variant?: VariantType;
  className?: string;
  children: React.ReactNode;
};

export const Container: React.FC<ConatinerProps> = ({
  children,
  variant,
  className,
}) => (
  <div
    className={`${containerStyles.container} ${
      containerStyles[variant!] ? containerStyles[variant!] : ""
    } ${className ? className : ""}`}
  >
    {children}
  </div>
);

export default Container;

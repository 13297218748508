import { graphql, useStaticQuery } from "gatsby";

import {
  CustomerPersonaType,
  HowYouGrowSectionType,
  OtherProductsType,
  WhatWeDeliverType,
  WhySimplepracticeSection,
} from "../Types/AcfGroupTypes";

type HomeTemplateType = {
  template: {
    whySimplepracticeSection: WhySimplepracticeSection;
    products: OtherProductsType;
    howYouGrowSection: HowYouGrowSectionType;
    whatWeDeliverSection: WhatWeDeliverType;
    customerPersonas: CustomerPersonaType;
    resourcesBlogRelationship: Queries.WpHomeTemplate_Resourcesblogrelationship;
  };
};

type HomepageLegacyQueryType = {
  allWpPage: {
    nodes: HomeTemplateType[];
  };
};

export const HomepageLegacyQuery = (): HomeTemplateType => {
  const {
    allWpPage: { nodes },
  } = useStaticQuery<HomepageLegacyQueryType>(graphql`
    query HomePageLegacyQuery {
      allWpPage(
        filter: { template: { templateName: { eq: "Home Template" } } }
      ) {
        nodes {
          template {
            ... on WpHomeTemplate {
              ...FragmentCustomerPersonas
              ...FragmentHowYouGrow
              ...FragmentOtherProducts
              ...FragmentPartneredWithLogosP
              ...FragmentWhatWeDeliver
              ...FragmentWhySimplePractice
              ...FragmentResourcesBlog
            }
          }
        }
      }
    }
  `);
  const [homePageTemplate] = nodes;

  return homePageTemplate;
};

export type notFoundFeatured = {
  urlLink: string;
  urlText: string;
  copy: string;
};

export const notFoundFeaturedData: notFoundFeatured[] = [
  {
    urlLink: "/",
    urlText: "Home",
    copy: `See why SimplePractice is the #1-rated solution for private
    practice`,
  },
  {
    urlLink: "/pricing/",
    urlText: "Pricing",
    copy: "Find the right plan for your growing solo or group practice.",
  },
  {
    urlLink: "/features/",
    urlText: "Features",
    copy: "Browse all the features that help practices like yours thrive.",
  },
  {
    urlLink: "/help-center/",
    urlText: "Help Center",
    copy: "Need some help? Find your answer or get in touch with us.",
  },
];

import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { notFoundFeaturedData } from "./notFoundFeaturedData";

import Container from "../UI/Container";
import NotFoundFeaturedList from "./NotFoundFeaturedList";

import styles from "./NotFoundContent.module.scss";

const NotFoundContent: React.FC = props => {
  const [reducePad, setReducePad] = useState(false);
  const notFoundSectionWrapper = `${styles.notFoundContent} ${
    reducePad ? styles.Minus72 : ""
  }`;

  useEffect(() => {
    const scrollCallBack = () => {
      if (window.pageYOffset > 200) {
        setReducePad(true);
      } else {
        setReducePad(false);
      }
    };

    window.addEventListener("scroll", scrollCallBack);

    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  return (
    <section
      data-testid="section:NotFoundContent"
      className={notFoundSectionWrapper}
    >
      <Container className={styles.notFoundContentHeadLine}>
        <h1>That page doesn&rsquo;t exist.</h1>
        <p>
          <Link to="/">Visit our homepage</Link> or try one of the links below
          instead:
        </p>
      </Container>

      <Container className={styles.notFoundContentGrid}>
        <NotFoundFeaturedList featuredList={notFoundFeaturedData} />
      </Container>
    </section>
  );
};

export default NotFoundContent;

import React from "react";
import { Builder } from "@builder.io/react";

import BuilderStringInput from "@utils/BuilderInputs/BuilderStringInput";
import LinkRedirectSignin, {
  LinkRedirectSigninType,
} from "@components/BuilderComponents/LinkRedirectSignin";

const LinkRedirectSigninWrapper: React.FC<LinkRedirectSigninType> = props => {
  return <LinkRedirectSignin {...props} />;
};

export default LinkRedirectSigninWrapper;

export const RegisterLinkRedirectSignin = () => {
  Builder.registerComponent(LinkRedirectSigninWrapper, {
    name: "link-redirect-signin",
    inputs: [new BuilderStringInput("linkCopy", { defaultValue: "" })],
  });
};

import React from "react";
import { Builder } from "@builder.io/react";

import { HomepageLegacyQuery } from "@StaticQueries/homepage-legacy.query";
import WhySimplePracticeSection from "@components/WhySPSection";

import styles from "./HomepageLegacy.module.scss";
import CustomerPersonaBannerContainer from "@containers/CustomerPersonaBannerContainer/CustomerPersonaBannerContainer";
import HowYouGrowContainer from "@containers/HowYouGrowContainer";
import { PageProps } from "gatsby";
import OtherProductsContainer from "@containers/OtherProductsContainer";
import WhatWeDeliverContainer from "@containers/WhatWeDeliverContainer";

const HomepageLegacy = () => {
  const location =
    typeof window !== "undefined" &&
    (window?.location as PageProps["location"]);
  const { template } = HomepageLegacyQuery();
  const {
    whySimplepracticeSection,
    customerPersonas,
    howYouGrowSection,
    products,
    whatWeDeliverSection,
    resourcesBlogRelationship,
  } = template;

  const whatWeDeliverProps = {
    ...whatWeDeliverSection,
    resourcesBlogRelationship,
  };

  return (
    <div className={styles.wrapper}>
      <WhySimplePracticeSection
        whySpContent={{ ...whySimplepracticeSection }}
        headerClassName={styles.simpleContainerHeaderSection}
      />
      <CustomerPersonaBannerContainer props={{ ...customerPersonas }} />
      <HowYouGrowContainer
        sectionData={howYouGrowSection}
        location={location}
      />
      <OtherProductsContainer content={products} />
      <WhatWeDeliverContainer props={whatWeDeliverProps} />
    </div>
  );
};

export default HomepageLegacy;

export const RegisterHomepageLegacy = () => {
  Builder.registerComponent(HomepageLegacy, {
    name: "homepage-legacy",
    noWrap: true,
  });
};
